import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import { PageComponent } from '../types/gatsby.types';
import joinClassNames from '../utils/className.utils';
import './page-enquiry.scss';
import ExpressInterestForm from '../components/ExpressInterestForm/EnquiryForm';
import { sanitize } from '../utils/sanitize.utils';
import BackToHomepageButton from '../components/WithQuery/BackToHomepageButton/BackToHomepageButton';
import APP_CONTENT_CONFIG from '../constants/appContentConfig.constants';

export const query = graphql`
query ($id: String) {
  wpPage(id: { eq: $id }) {
    ...PageWithFeaturedImageFragment
  }
}
`;

type PageEnquiryProps = {
  data: any,
}

const PageEnquiry: PageComponent<PageEnquiryProps> = props => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, featuredImage, seo },
    },
  } = props;

  const { enquiryForm } = APP_CONTENT_CONFIG.page;
  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      wpYoastSEO={seo}
      className={joinClassNames(slug)}
      pageSlug={slug}
      title={title}
      isFrontPage={isFrontPage}
      featuredMedia={featuredImage?.node}
    >

      <div className="page-section PageSectionEnquiry section-centered-page">
        <div className="container">
          <header className="section-page-header">
            <h1>{title}</h1>
            <h2>{enquiryForm.header.h2Text}</h2>
            {enquiryForm.header.Separator}
            <div className="section-centered-page__header-content" dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
          </header>

          <div className="PageSectionEnquiry__Body">
            <ExpressInterestForm />
          </div>

          <footer className="PageSectionEnquiry__Footer">
            <BackToHomepageButton />
          </footer>
        </div>
      </div>

    </PageTemplate>
  )} />
}

export default PageEnquiry;