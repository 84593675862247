import { graphql, useStaticQuery } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { GeneralSettings } from '../../../types/wordpress.types';
import BaseIcon from '../../BaseIcon/BaseIcon';
import BaseLink from '../../BaseLink/BaseLink';

type BackToHomepageButtonProps = {}

const BackToHomepageButton: React.FC<BackToHomepageButtonProps> = props => {

  const { siteInfo } = useStaticQuery(graphql`
    {
      ...SiteInfoAndSettingsFragment
    }
  `)

  const generalSettings: GeneralSettings = siteInfo.generalSettings;
  const homepageUrl = "/";

  return <BaseLink className="button" to={homepageUrl}>
    <BaseIcon icon="home" size={16} />
    <span>Back to homepage</span>
  </BaseLink>
}

export default BackToHomepageButton;