import { Observer } from 'mobx-react-lite';
import React, { SyntheticEvent } from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import { normalizeString } from '../../utils/string.utils';
import './CountrySelector.scss';
import { countries } from '../../constants/countries.constants';
import { action } from 'mobx';

type CountrySelectorProps = {
  value: string,
  onChange: (value: string) => void,
}

const CountrySelector: React.FC<CountrySelectorProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    _value: p.value as string,
    get value() { return s._value },
    set value(v: string) {
      s._value = v;
    },
    get countriesSorted() {
      return countries.sort((a, b) => {
        const an = normalizeString(a.name);
        const bn = normalizeString(b.name);
        return an > bn ? 1 : an < bn ? -1 : 0;
      });
    },
    handleOptionClick: action((e: SyntheticEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      s._value = value;
      p.onChange?.(value);
    }),
  }))

  return <Observer children={() => (
    <select value={s.value} onChange={s.handleOptionClick}>
      {s.countriesSorted.map((country) => (
        <option key={country.cca2} value={country.cca2}>
          {country.name}
        </option>
      ))}
    </select>
  )} />
}

export default CountrySelector;