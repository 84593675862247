import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../types/base.types';
import { useProps, useStore } from '../../utils/mobx.utils';
// import './BaseInput.scss';

type BaseInputProps<T extends AnyObject = AnyObject> = {
  form: T,
  field: keyof T,
  type?: 'text' | string,
  name?: string,
  autoComplete?: string,
  onChange?: (value: any) => void,
}

const BaseInput = <T extends AnyObject = AnyObject>(props: BaseInputProps<T>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get value() { return p.form[p.field] },
    get commonAttr() {
      return {
        value: s.value,
        name: p.name ?? p.field as string,
        type: p.type ?? 'text',
        autoComplete: p.autoComplete,
        onChange: s.handleChange,
      }
    },
    handleChange: action((e) => {
      const { value } = e.currentTarget;
      p.form[p.field] = value;
      p.onChange?.(value);
    })
  }))
  return <Observer children={() => (
    <input
      {...s.commonAttr}
    />
  )} />
}

export default BaseInput;