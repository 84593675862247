import Axios from 'axios';
import { action, toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useControllers } from '../../controllers/app.controller';
import { IS_DEV } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { AnyObject, Nullable } from '../../types/base.types';
import { useStore } from '../../utils/mobx.utils';
import { autoPluralize } from '../../utils/string.utils';
import BaseInput from '../BaseInput/BaseInput';
import CountrySelector from '../CountrySelector/CountrySelector';
import ProductCard from '../ProductCard/ProductCard';
import BaseIcon from '../BaseIcon/BaseIcon';
import APP_CONTENT_CONFIG, { WP_ORIGIN } from '../../constants/appContentConfig.constants';
import useAllWpProduct from '../../content/AllWpProduct/AllWpProduct';
import './EnquiryForm.scss';

type EnquiryFormProps = {}

const EnquiryForm: React.FC<EnquiryFormProps> = props => {

  const products = useAllWpProduct();

  const { WISHLIST, STORE } = useControllers();

  const { enquiryForm } = APP_CONTENT_CONFIG.page;
  const s = useStore(() => ({
    isSubmitting: false,
    submitError: false,
    submitted: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      meta: {
        country: enquiryForm.form.countryCode,
        tourStartDate: '',
        tourEndDate: '',
        numberOfGuests: 1,
        typeOfTransport: '',
        comment: '',
        wishlist: [] as string[],
      }
    },
    get products() { return products },
    get wishlist() { return WISHLIST.wishlist },
    get submitButtonLabel() {
      return s.isSubmitting ? 'Submitting…' : s.submitError ? 'Retry' : 'Submit Enquiry';
    },
    autoPluralize: autoPluralize,
    handleFieldChange: <T extends AnyObject = AnyObject>(form: T, field: keyof T) => action((e: any) => {
      const { value } = e.currentTarget;
      form[field] = value;
      s.saveFormToStorage();
    }),
    saveFormToStorage() {
      STORE.set('EnquiryForm', JSON.stringify(s.form));
    },
    submit() {
      s.isSubmitting = true;
      const url = WP_ORIGIN + '/wp-json/form-api/v1/form/submission/1';
      const { form, wishlist } = s;
      const payload = { ...toJS(form) }
      payload.meta.wishlist = wishlist.map(i => {
        const product = s.products.find(p => p.id === i.productId);
        if (product) return product.title;
        else return 'id-' + i.productId;
      });
      Axios.post(url, payload).then(response => {
        s.onSuccess(payload);
      }).catch(e => {
        console.error(e);
        s.submitError = true;
      }).then(() => {
        s.isSubmitting = false;
      })
    },
    onSuccess(payload: AnyObject) {
      STORE.set('last-submitted-form', JSON.stringify(payload));
      window.location.href = '/thank-you';
      STORE.remove('EnquiryForm');
      WISHLIST.clear();
    },
    getProductById(id: Nullable<string | number>) {
      return s.products.find(p => p.id === id);
    },
  }))

  useOnMount(() => {
    const cachedForm = STORE.get('EnquiryForm');
    if (!cachedForm) return;
    action(() => Object.assign(s.form, cachedForm))();

    IS_DEV && Reflect.set(window, 'SUBMIT_FORM', s);
  })

  const { wishlistBtn } = APP_CONTENT_CONFIG;
  return <Observer children={() => (
    <div className="EnquiryWrapper">
      <div className="EnquiryForm">
        <h3>Your Information</h3>
        <div className="form-field">
          <label>First Name *</label>
          <BaseInput form={s.form} field="firstName" autoComplete="firstName" onChange={s.saveFormToStorage} />
        </div>
        <div className="form-field">
          <label>Last Name *</label>
          <BaseInput form={s.form} field="lastName" autoComplete="lastName" onChange={s.saveFormToStorage} />
        </div>
        <div className="form-field">
          <label>Phone</label>
          <BaseInput form={s.form} field="phone" type="tel" autoComplete="mobile" onChange={s.saveFormToStorage} />
          <p>
            <em>* Please include country code</em>
          </p>
        </div>
        <div className="form-field">
          <label>Email *</label>
          <BaseInput form={s.form} field="email" type="email" autoComplete="email" onChange={s.saveFormToStorage} />
        </div>
        <div className="form-field span-all">
          <label>Country of Residence *</label>
          <CountrySelector value={s.form.meta.country} onChange={(v) => { s.form.meta.country = v; s.saveFormToStorage() }} />
        </div>
        <h3>About your visit</h3>
        <div className="form-field">
          <label>Preferred tour dates from</label>
          <BaseInput form={s.form.meta} field="tourStartDate" type="date" onChange={s.saveFormToStorage} />
        </div>
        <div className="form-field">
          <label>to</label>
          <BaseInput form={s.form.meta} field="tourEndDate" type="date" onChange={s.saveFormToStorage} />
        </div>
        <div className="form-field">
          <label>Number of guests</label>
          <BaseInput form={s.form.meta} field="numberOfGuests" type="number" onChange={s.saveFormToStorage} />
        </div>
        <div className="form-field">
          <label>Preferred type of transport</label>
          <select value={s.form.meta.typeOfTransport} onChange={
            s.handleFieldChange(s.form.meta, 'typeOfTransport')}>
            <option value="unspecified">Please select</option>
            <option value="self-drive">Self-drive</option>
            <option value="Chauffeur-driven">Chauffeur-driven</option>
          </select>
        </div>
        <div className="form-field span-all">
          <label>Comment</label>
          <textarea value={s.form.meta.comment} rows={5}
            onChange={s.handleFieldChange(s.form.meta, 'comment')} />
        </div>
        {s.wishlist && <>
          <h3 className="wishlist-section-title">
            <BaseIcon icon={wishlistBtn.navIcon} />
            {" Your wishlist "}
            {s.wishlist.length > 0 && <span>
              ({autoPluralize(s.wishlist.length, 'item')})
            </span>}
          </h3>
          <div className="EnquiryForm__wishlist span-all">
            {s.wishlist.length > 0 ?
              <ul>
                {s.wishlist.map(item => {
                  const product = s.getProductById(item.productId)
                  return product && <li key={item.id}>
                    <ProductCard
                      product={product}
                      canViewDetails
                      shouldAnimateIn
                    />
                  </li>
                })}
              </ul> : <div className="EnquiryForm__wishlist__empty-notice">
                <h4>You do not have any items in your wishlist yet.</h4>
              </div>
            }
          </div>
        </>}
        <div className="form-footer span-all">
          <button type="button" className="button primary larger" onClick={s.submit}>
            <span>{s.submitButtonLabel}</span>
          </button>
        </div>
      </div>
    </div>
  )} />
}

export default EnquiryForm;